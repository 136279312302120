import type { BootData } from 'back/src/apps/api-ui/routes/001_boot'
import { defineNuxtRouteMiddleware, navigateTo } from '#app'
import dayjs from 'dayjs'
import { useFetchCall } from '~/composables/useFetchCall'
import { useAppStore } from '~/stores/app'
import { useConfigStore } from '~/stores/config'
import { useUserStore } from '~/stores/user'
import { loadFieldsConfig } from '~/utils/fieldsConfig'
import { systemLanguagesLoad } from '~/utils/languages'
import { UnitStore } from '~/utils/UnitStore'

let boot = true
export default defineNuxtRouteMiddleware(async (to, from) => {
    if (!boot) {
        // если данные уже загружены, пропускаем
        return
    }
    if (String(to.name).startsWith('auth')) {
        // разрешена навигация без авторизации
        return
    }

    const appStore = useAppStore()
    const userStore = useUserStore()
    const data = await useFetchCall<BootData, any>('/boot', {})
    useConfigStore().boot(data.config)

    const { abilities, ...userRest } = data.user
    userStore.$patch(userRest)
    userStore.abilities = new Set(abilities)

    appStore.$patch({
        appLanguages: data.languages,
        langData: data.user.settings.langData,
        backVersion: data.version,
        abilityList: new Map(data.abilityList.map(i => [i.sid, i])),
    })
    appStore.setLangUI(data.user.settings.langUi)
    dayjs.locale(data.user.settings.langUi)

    systemLanguagesLoad(data.languages)
    loadFieldsConfig(data.dfc)
    boot = false

    // load SPA version
    fetch('/version.txt')
        .then(r => r.text())
        .then(frontVersion => {
            appStore.$patch({ frontVersion })
        })

    // eslint-disable-next-line no-new
    new UnitStore()
    // todo? setup user load every 15m
    const returnToAfterAuth = sessionStorage?.getItem('returnToAfterAuth')
    if (returnToAfterAuth) {
        sessionStorage.removeItem('returnToAfterAuth')
        return navigateTo(returnToAfterAuth)
    }
})
