import type { UnitCurrencyFilter, UnitCurrencyList } from 'back/src/services/UnitCurrency'
import type { TMultiText } from '~/types'
import { reactive } from 'vue'
import { useFetchCall } from '~/composables/useFetchCall'

const CACHE_TTL = 8_000_000 // 30min
const CHUNK_SIZE = 100
const ROUTE_PATH = '/unitCurrencyList'
let store: InstanceType<typeof UnitStore> | undefined // singleton

interface TUnit {
    id: string
    sid: string
    name: TMultiText
    label: TMultiText
}

interface TCurrency {
    id: string
    sid: string
    name: TMultiText
    symbol: string
    codeAlphabet: string | null
}

export class UnitStore {
    private cache = new Map<string, (TUnit | TCurrency)>()

    private watcherTimer: ReturnType<typeof setTimeout> | undefined = undefined
    private reloadInterval: ReturnType<typeof setInterval> | undefined = undefined
    private loadingList: Set<string> = new Set()

    public tasks = reactive<Set<string>>(new Set())

    constructor() {
        if (!store) {
            this.init()
            // eslint-disable-next-line ts/no-this-alias
            store = this
        }
        return store
    }

    private async loadList() {
        return this.afterLoad(await useFetchCall<UnitCurrencyList, UnitCurrencyFilter>(ROUTE_PATH, {}))
    }

    private init() {
        this.loadList().then() // noop
        this.reloadInterval = setInterval(() => this.loadList(), CACHE_TTL)
        this.watcher()
    }

    private addTasks(uuidList: string[]) {
        uuidList.forEach(i => this.tasks.add(i))
        return null
    }

    private watcher() {
        this.watcherTimer = setTimeout(() => this.watcher(), 5_000)
        this.loadFromTasks()
    }

    private loadFromTasks() {
        if (!this.tasks.size) {
            return
        }

        const list = [...this.tasks].filter(i => !this.loadingList.has(i))
        if (!list.length) {
            return
        }
        const chunk = list.splice(0, CHUNK_SIZE)
        this.load(chunk)
    }

    private load(uuidList: string[]) {
        uuidList.forEach(i => this.loadingList.add(i))
        useFetchCall<UnitCurrencyList, UnitCurrencyFilter>(ROUTE_PATH, { uuidList }).then(res => this.afterLoad(res))
    }

    private afterLoad(res: (TUnit | TCurrency)[]) {
        res.forEach(i => {
            this.cache.set(i.id, i)
            this.tasks.delete(i.id)
            this.loadingList.delete(i.id)
        })
        this.watcher()
    }

    getUnit(uuid: string) {
        if (typeof uuid !== 'string') {
            throw new TypeError(`uuid must be a string got: ${typeof uuid}`)
        }
        return this.cache.get(uuid) || this.addTasks([uuid])
    }

    private kill() {
        clearInterval(this.reloadInterval)
        clearTimeout(this.watcherTimer)
        store = undefined
    }
}
