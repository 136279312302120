import payload_plugin_K629R2esWBjSAINQVKCTWyLt1kpEfI2gpHzIMMmaGnE from "/build/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_nJbkLYoq9al4DVGfrwGpNwdzon5eBsXzl0lCDbxxrUY from "/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_b_f811dTL_awBaZhYH08f8Db_bQMMlQYlpMPtoe7n7c from "/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_c9PFreqQNE_iGlx42BDS6BQS1hFKBIyCGsfBIJAvFH8 from "/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_rJRQK0TMBboZcUDIqsAuPu934XiL5qAAxDdxrZ__0r8 from "/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_GLGsFbulfUN8qOrjV1Oe2ISND_S49zh7HE9A1O6ja34 from "/build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Io86IcoxvWG2W3X04r0dtuH7OES9LYBjzZEgn4l2OBw from "/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_8M_OwQXeaqnJAr8q0xH4PT1WYzJA1AnH7MVH40ql_G0 from "/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_OXQZF4SIGwKvC2ltUuLC9__N0H6iOIQ_1DX3zcol9_c from "/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/build/packages/front/.nuxt/components.plugin.mjs";
import prefetch_client_usSU70_PwDtu0IWV9YOSUBm27VIyY0wu21VUrO_zP1c from "/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_prIcmzFj3G5TS5_18Ig3U0jH7TpfTZSAwzMPs6z5uoo from "/build/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import sentry_client_GKntg0Yp8i_Bv7FFjEAsQL6wb614i4dC45R_tmcmcHM from "/build/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/build/packages/front/.nuxt/sentry-client-config.mjs";
import can_UAGjma0GNaRe9GD5BBYoZyXTGa1_8NpvVn5dCJh6TU4 from "/build/packages/front/plugins/can.ts";
import error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU from "/build/packages/front/plugins/error-handler.ts";
import i18next_n0KELn2Gz9tzNrc8vlnTmuBF881d2VcFos9qKPZtpTk from "/build/packages/front/plugins/i18next.ts";
import maska_3Pdj_1ePE2_bvNiJO2MTIpupg59ALfbg8NtShv_Xmbo from "/build/packages/front/plugins/maska.ts";
import visits_hRDPciMiSk4QKY3h2AtsmN0BNPAoDrusy8x396QAPPc from "/build/packages/front/plugins/visits.ts";
export default [
  payload_plugin_K629R2esWBjSAINQVKCTWyLt1kpEfI2gpHzIMMmaGnE,
  revive_payload_client_nJbkLYoq9al4DVGfrwGpNwdzon5eBsXzl0lCDbxxrUY,
  unhead_b_f811dTL_awBaZhYH08f8Db_bQMMlQYlpMPtoe7n7c,
  router_c9PFreqQNE_iGlx42BDS6BQS1hFKBIyCGsfBIJAvFH8,
  payload_client_rJRQK0TMBboZcUDIqsAuPu934XiL5qAAxDdxrZ__0r8,
  navigation_repaint_client_GLGsFbulfUN8qOrjV1Oe2ISND_S49zh7HE9A1O6ja34,
  check_outdated_build_client_Io86IcoxvWG2W3X04r0dtuH7OES9LYBjzZEgn4l2OBw,
  chunk_reload_client_8M_OwQXeaqnJAr8q0xH4PT1WYzJA1AnH7MVH40ql_G0,
  plugin_vue3_OXQZF4SIGwKvC2ltUuLC9__N0H6iOIQ_1DX3zcol9_c,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_usSU70_PwDtu0IWV9YOSUBm27VIyY0wu21VUrO_zP1c,
  plugin_prIcmzFj3G5TS5_18Ig3U0jH7TpfTZSAwzMPs6z5uoo,
  sentry_client_GKntg0Yp8i_Bv7FFjEAsQL6wb614i4dC45R_tmcmcHM,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  can_UAGjma0GNaRe9GD5BBYoZyXTGa1_8NpvVn5dCJh6TU4,
  error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU,
  i18next_n0KELn2Gz9tzNrc8vlnTmuBF881d2VcFos9qKPZtpTk,
  maska_3Pdj_1ePE2_bvNiJO2MTIpupg59ALfbg8NtShv_Xmbo,
  visits_hRDPciMiSk4QKY3h2AtsmN0BNPAoDrusy8x396QAPPc
]