import { useNuxtApp } from '#app'
import { useAppStore, useRoute } from '#imports'
import { defineNuxtPlugin } from 'nuxt/app'
import { h, render } from 'vue'
import AbilitiesTooltip from '~/components/Abilities/AbilitiesTooltip.vue'
import { useUserStore } from '~/stores/user'

export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.vueApp.directive('can', (el, binding, vnode) => {
        const { value: abilities } = binding
        if (abilities == null) {
            return
        }
        let mode = 'default'
        if (binding.modifiers.disable) {
            mode = 'disable'
        }
        if (binding.modifiers.remove) {
            mode = 'remove'
        }

        const route = useRoute()
        const isDemo = route.params.sid === '0' || route.query.example === '1'
        const abilitiesList: string[] = String(abilities).split('|')

        const canUse = useUserStore().can
        if (!isDemo && !abilitiesList.some(sid => canUse(sid))) {
            const props = (vnode as any).ctx.props || {}
            const hasDisable = 'disable' in props
            if (['default', 'disable'].includes(mode) && hasDisable) {
                props.disable = true
            }
            if (mode === 'remove' || (mode === 'default' && !hasDisable)) {
                el.remove()
            }
            return
        }

        if (!isDemo) {
            return
        }

        const targetSid = (route.query.target || '') as string
        const isTarget = abilitiesList.includes(targetSid)
        if (isTarget) {
            el.classList.add('ability-target--item')
        }

        const abilitiesMap = useAppStore().abilityList
        const vueComponent = h(AbilitiesTooltip, { abilities: abilitiesList.map(i => abilitiesMap.get(i)).filter(Boolean) as any, isTarget, app: useNuxtApp() })
        render(vueComponent, el)
    })
})
