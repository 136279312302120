<template>
    <div class="loading flex" :class="size">
        <RIcon color="var(--app-color-a)" :width="compIconWidth" name="spinner" />
        <span v-if="compText">{{ compText }}</span>
    </div>
</template>

<script setup lang="ts">
import { useTranslation } from 'i18next-vue'
import { computed } from 'vue'

type Text = string
type Size = 'small' | 'normal' | 'big' | 'full'

const props = withDefaults(defineProps<{ text?: Text, size?: Size }>(), {
    text: undefined,
    default: 'normal',
})

const { t } = useTranslation()

const compText = computed(() => props.text ?? `${t('@loading')}...`)
const compIconWidth = computed(() => {
    switch (props.size) {
        case 'full':
            return 50
        case 'big':
            return 30
        case 'normal':
            return 22
        case 'small':
            return 16
        default:
            return 22
    }
})
</script>

<style>
.loading {
    height: 22px;
    line-height: 22px;
    margin: 1em 0.6em;

    &.small {
        margin: 0.5em;
    }

    &.big {
        font-size: 16px;
    }

    &.full {
        height: unset;
        font-size: 22px;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .r-icon {
            margin: 0;
        }
    }

    .r-icon {
        margin-right: 5px;
    }
}
</style>
