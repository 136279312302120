export function useBase() {
    function randKey() {
        let id = Date.now()
            .toString()
            .split('')
            .reverse()
            .map(() => rand(0, 9))
            .join('')
        return `r-${id}`
    }
    function rand(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    function toKebabCase(str: string) {
        return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
    }

    function formatBytes(bytes: number, decimals = 2) {
        if (!+bytes) {
            return '0 Bytes'
        }

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${Number.parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
    }

    return { randKey, toKebabCase, formatBytes }
}
