export function useAbilityImpact() {
    function impactColor(impact: number) {
        switch (impact) {
            case 1:
            case 2:
            case 3:
                return 'green'
            case 4:
                return 'orange'
            case 5:
                return 'red'
            default:
                return 'grey'
        }
    }

    function labelBySid(sid: number | string) {
        return `0000${sid}`.slice(-5)
    }

    return { impactColor, labelBySid }
}
