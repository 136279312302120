
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as hierarchyH6PEitEVS2EGRxDeEThkMehA7SJ_45HaINlAEEC_nTEOQMeta } from "/build/packages/front/pages/abilities/[sid]/hierarchy.vue?macro=true";
import { default as historybqlDQ9ajiLhT7iA44dGcaR8z4DYPttB1TFFrE9v_YWgMeta } from "/build/packages/front/pages/abilities/[sid]/history.vue?macro=true";
import { default as indexGOEoGl7HS7d3WwBmZ_45_tIdjgbpcTrKSyBscx7PgsYpIMeta } from "/build/packages/front/pages/abilities/[sid]/index.vue?macro=true";
import { default as _91sid_93DVtEDBbG9sayJScDPiaLbcdqegoFp9NOTCqCwBlApJIMeta } from "/build/packages/front/pages/abilities/[sid].vue?macro=true";
import { default as indexCHt_Dx5KZdPqt82NRHbfvoq8LGgyzg1KdvNWOoaRzOQMeta } from "/build/packages/front/pages/abilities/index.vue?macro=true";
import { default as auth_45errorNZVdke_45Zl49ST91FbBOVK06f8QEvd0AkVzuug988feAMeta } from "/build/packages/front/pages/auth-error.vue?macro=true";
import { default as authpCDSlZRO5_bAl2TcA9mzE9AmwKMLRr5W_rNLQ_45BIa30Meta } from "/build/packages/front/pages/auth.vue?macro=true";
import { default as historyvQNaRxQ5SNmWqSi_GUC6628vJbN8WZ51TpgIrh8qnAMMeta } from "/build/packages/front/pages/business-statuses/[sid]/history.vue?macro=true";
import { default as indexcJrppOAgLX3Cm0kPqkzD6IAibbo2r_45sMHzMt0Cjic7kMeta } from "/build/packages/front/pages/business-statuses/[sid]/index.vue?macro=true";
import { default as _91sid_93_45ZuK2F7xGRB_45UMLmPnf87ONFdVV_Aup3P0nxxGPzUP4Meta } from "/build/packages/front/pages/business-statuses/[sid].vue?macro=true";
import { default as indexbuVgTqAR_K3WuLtigCVDSXWKz_455VF6Ky_uZ38ryTOYUMeta } from "/build/packages/front/pages/business-statuses/index.vue?macro=true";
import { default as characteristicslngVmv5Y_450_76NDRo16WVCBxWYOVCGNAKxZ4aOn4k8YMeta } from "/build/packages/front/pages/characteristics-field-names/[sid]/characteristics.vue?macro=true";
import { default as historyT0lUxZ7_ZrSwUGFVa9FtibICfTEvZbm_455ltLMz2496IMeta } from "/build/packages/front/pages/characteristics-field-names/[sid]/history.vue?macro=true";
import { default as indexBiOLpwD8eeEP_45ud2pWR9w1jcKCLeaNTVWmCumb5x0VEMeta } from "/build/packages/front/pages/characteristics-field-names/[sid]/index.vue?macro=true";
import { default as _91sid_93RmItL76NPLdcTB4VejCJsKgDbmhBi9FQcufXNtbzwPIMeta } from "/build/packages/front/pages/characteristics-field-names/[sid].vue?macro=true";
import { default as indexmpoHGBd_45IwiL38lzlF4jLEiQeFk2VlbV8coryv1R03sMeta } from "/build/packages/front/pages/characteristics-field-names/index.vue?macro=true";
import { default as characteristicshds81h46UlAz_45d6fWUyoEHVbwYWOD0s_SoQcyQH6ax8Meta } from "/build/packages/front/pages/characteristics-groups/[sid]/characteristics.vue?macro=true";
import { default as historyfaUsQov90_455uUnEp4SCYGF3yixph6nOWgJKu0M_45et9wMeta } from "/build/packages/front/pages/characteristics-groups/[sid]/history.vue?macro=true";
import { default as indexI_45BDgyHvdYUM8JiCeE_9gwS_j7Z4Ls6O0xRv_a1Fj44Meta } from "/build/packages/front/pages/characteristics-groups/[sid]/index.vue?macro=true";
import { default as _91sid_93fJEYqL_45kG_zkgt46vt5VKf27ujCWMO46XPbhGLYNkVIMeta } from "/build/packages/front/pages/characteristics-groups/[sid].vue?macro=true";
import { default as indexUqM5gippDiNIF1Rxlq_45QbMLwLHzPNt632epuKtGPGRIMeta } from "/build/packages/front/pages/characteristics-groups/index.vue?macro=true";
import { default as data_45sourcesKrQ7F6me_45_RGZ9yUQ94qYmMp4IFF9QKLk09a68I4mOEMeta } from "/build/packages/front/pages/characteristics/[sid]/data-sources.vue?macro=true";
import { default as fields1BEjA1BkmwPdPs7_j0wtTDdm183JPwCu_45Ik32I_ivoMMeta } from "/build/packages/front/pages/characteristics/[sid]/fields.vue?macro=true";
import { default as historyuL_45yOm8KqNZCb5x6ZcfA3imGvPRJF59SUIz4UU_45r174Meta } from "/build/packages/front/pages/characteristics/[sid]/history.vue?macro=true";
import { default as indexb_cexbrOHJ2JclBTvFXXWUVq2rQd5uHKw53xHRy0IDUMeta } from "/build/packages/front/pages/characteristics/[sid]/index.vue?macro=true";
import { default as _91sid_93F8N4BAayfzqnbQHM6ThSqZE932_459sXw7YqeKyrIYHE0Meta } from "/build/packages/front/pages/characteristics/[sid].vue?macro=true";
import { default as indexzEnGtJzwXpR3ThqmciMv9wEgg4KhjIRhhhUTbMitkcoMeta } from "/build/packages/front/pages/characteristics/index.vue?macro=true";
import { default as historyJzaznEoeeIJ9A576TN3TxY2dAuYmmcPQ_45jRkXtN7XrUMeta } from "/build/packages/front/pages/countries/[sid]/history.vue?macro=true";
import { default as index6h77_45IvyrfGw4ryqIByRqFSoFeINDTYBPV1slHExtDYMeta } from "/build/packages/front/pages/countries/[sid]/index.vue?macro=true";
import { default as _91sid_93RTgIYJ1A_BZevCa_451IH7dvRU1thJZWQEgOIhn2_453P9cMeta } from "/build/packages/front/pages/countries/[sid].vue?macro=true";
import { default as indexWkPXewWFfFgrYYnzfeCXRWYpXwQ9_nHEFT_S64wkH5kMeta } from "/build/packages/front/pages/countries/index.vue?macro=true";
import { default as history6J5evP4vyoRi3gdgOHHbu0HpZvFkTL1gCHCcXHM4_VEMeta } from "/build/packages/front/pages/currencies/[sid]/history.vue?macro=true";
import { default as indexJ3qPBOnYjQDJ5x2GF4aKkO8px5DmIvJ433f1n_45_6FacMeta } from "/build/packages/front/pages/currencies/[sid]/index.vue?macro=true";
import { default as _91sid_93MvcEvsNYKFkeL6S0ixCp0xXpW_wo70AYwz9ocx_45yTiEMeta } from "/build/packages/front/pages/currencies/[sid].vue?macro=true";
import { default as indexdAqmq7eqi_456TSjaI9E2CH_OqKf46ij_45EBVWfgVZx9pUMeta } from "/build/packages/front/pages/currencies/index.vue?macro=true";
import { default as indextIG2lpBZIf7C0VqDBSXzsKu0YQtUKV3QSn5U72KP03sMeta } from "/build/packages/front/pages/dev/index.vue?macro=true";
import { default as history0hupsIk3_lIgbjsUuLtdgRDJclcUYPZ3KN1KQ5GsOGEMeta } from "/build/packages/front/pages/documents/[sid]/history.vue?macro=true";
import { default as indexf0TfyA3HDTe0qBuHf0GcX0Zz2qyhQIBnCo7kqSU6Z0wMeta } from "/build/packages/front/pages/documents/[sid]/index.vue?macro=true";
import { default as _91sid_93ZHAA79Aq5Ns798rq5bL9rPgxp_45blggcriYdur_pJyjQMeta } from "/build/packages/front/pages/documents/[sid].vue?macro=true";
import { default as indexsz_SjWaCUk1e8Vu5NeLY46vsYGseVo9iuD1opUxo5TIMeta } from "/build/packages/front/pages/documents/index.vue?macro=true";
import { default as historym1G1P35QvH9PAz2t2bF2DZVHU5nBL2ARPb9yyJSsSU4Meta } from "/build/packages/front/pages/files/[sid]/history.vue?macro=true";
import { default as indexe9lWJJlx6R36XTe4GXFDnj_45rZbfoGOr8h3gyz6ICtxsMeta } from "/build/packages/front/pages/files/[sid]/index.vue?macro=true";
import { default as _91sid_93g23rR6t4FNGTXV7cBBnFbbcPka3ygQS5IcIGUlfsELIMeta } from "/build/packages/front/pages/files/[sid].vue?macro=true";
import { default as indexYxzrZ1fFe2QL4Kw4hoXNvffEQO8_4549aVjLNoXPDq7wIMeta } from "/build/packages/front/pages/files/index.vue?macro=true";
import { default as historyBPojViWQChJp6OT0pVEqpSWE4wffcQMiE50rcTJ1eewMeta } from "/build/packages/front/pages/history.vue?macro=true";
import { default as idMsb7n2eIpgWcP_459YTKAq9dvExUe2d5KiK_45Z7NYDKOG0Meta } from "/build/packages/front/pages/id.vue?macro=true";
import { default as indextSvbk_GEujLBszfovM7qvHubN9rWY_45jfhDcu6d78o4UMeta } from "/build/packages/front/pages/index.vue?macro=true";
import { default as detailLhje0uYQbpZsLP0c2g9GjxylskF29xaJ9T4UrW_45ggl0Meta } from "/build/packages/front/pages/languages/[sid]/detail.vue?macro=true";
import { default as historykGMmIjWHtarEhZtIFsm6wehkrYAUQ9XORf5VhYdpkJQMeta } from "/build/packages/front/pages/languages/[sid]/history.vue?macro=true";
import { default as indexVCasAMaQHYA6jmjTClKh_45qOtvhdPlaAloNONWfoHXwsMeta } from "/build/packages/front/pages/languages/[sid]/index.vue?macro=true";
import { default as testFYXm8OiYPpClx_OnRBlUzklRxUrBqbzuxVyDWMChcfcMeta } from "/build/packages/front/pages/languages/[sid]/test.vue?macro=true";
import { default as _91sid_93bkZFSjSV7tSJsAaR9d45SOT1m5EWTKFkXiME2p_LrmAMeta } from "/build/packages/front/pages/languages/[sid].vue?macro=true";
import { default as indexqahnsTAcAA9rg0zLEymvj1b4HIgDnMn7GfR_45z40dqfYMeta } from "/build/packages/front/pages/languages/index.vue?macro=true";
import { default as history55ySDVpk5J_45U1kDxxhpq9WmN6Mouttnhp19c5WnaNUoMeta } from "/build/packages/front/pages/manufacturers/[sid]/history.vue?macro=true";
import { default as indexrrvTMUP2V0a2Q3v8yS45aYstMtuHLDY52MFD4d0K3MEMeta } from "/build/packages/front/pages/manufacturers/[sid]/index.vue?macro=true";
import { default as _91sid_93_45Eez5ch7ux31xr92y8ZeLLu0W1b_45g1BYMQ0dsvje7DMMeta } from "/build/packages/front/pages/manufacturers/[sid].vue?macro=true";
import { default as index6wzZ0ZaRF7jj7hgyZ7bwbfDPDUSp5eTWt0ngmc2g07YMeta } from "/build/packages/front/pages/manufacturers/index.vue?macro=true";
import { default as descriptoriAnjd19MC7FWg8KjoXZFoFDt_1JS9MScVVBzoJqKph0Meta } from "/build/packages/front/pages/materials-classes/[sid]/descriptor.vue?macro=true";
import { default as history_xhsxPfwLdaWlKuRVEa5iSqvJyUTUUzCAgRXWp9IX_45EMeta } from "/build/packages/front/pages/materials-classes/[sid]/history.vue?macro=true";
import { default as indexbP_LCOAJYrohNmByh1H8XY2dnoJfhHKwBw3MZV5UtbUMeta } from "/build/packages/front/pages/materials-classes/[sid]/index.vue?macro=true";
import { default as _91sid_939vH5FTVgzf05ar06O_45RxvE_2pnLUhKy2j1zVX_9EWcoMeta } from "/build/packages/front/pages/materials-classes/[sid].vue?macro=true";
import { default as indexgGwH0B8zFNlinPCa7Rq96e5bPFB7IFRkun6QSSfAWlMMeta } from "/build/packages/front/pages/materials-classes/index.vue?macro=true";
import { default as historyBQiEn33xGq6CM4XHKh3k1AK_hjxqW3q82_NXpKXKPpkMeta } from "/build/packages/front/pages/materials-types/[sid]/history.vue?macro=true";
import { default as indexG87Kut9DZ6_wu50Wc1U_45saS3HB9ePyEXOYrmaDiOWE0Meta } from "/build/packages/front/pages/materials-types/[sid]/index.vue?macro=true";
import { default as _91sid_93JVg4oc_y_ZsAs49gHCljEhys_45YkakZdm7mDjWMr5u9gMeta } from "/build/packages/front/pages/materials-types/[sid].vue?macro=true";
import { default as indexD7b3B8wb_fSo1GMhnrEL1HqW4IyKV4kQ9kNP_6lUFZgMeta } from "/build/packages/front/pages/materials-types/index.vue?macro=true";
import { default as fieldsGHpFuA5OEQG_0I1NeFafspj_455sXzfNIsqj_Algy3lqkMeta } from "/build/packages/front/pages/references/[sid]/fields.vue?macro=true";
import { default as historylrgVc5BDrR6W41SPGJHlY6v7ncxbi4Rhy6hndRmVnh4Meta } from "/build/packages/front/pages/references/[sid]/history.vue?macro=true";
import { default as index0AfCU1AR_th7LTmeSPL_45F9cewTNbqVhnAD9vJYywVaQMeta } from "/build/packages/front/pages/references/[sid]/index.vue?macro=true";
import { default as recordszwQ03AifuvDHRtG4R0gkcVantJOWQ05zD29OJG9brHcMeta } from "/build/packages/front/pages/references/[sid]/records.vue?macro=true";
import { default as _91sid_93Hb1xxFMc_45i71OAsTakVUCfThzE9fXtjM2ljhKR_45iCqEMeta } from "/build/packages/front/pages/references/[sid].vue?macro=true";
import { default as indexCbIjvDH1DJMBG8InhqGf6hhUehlPZc235v7e7AYj8ngMeta } from "/build/packages/front/pages/references/index.vue?macro=true";
import { default as history_457jTIGz_45q6nhNw5qjHu1xXSDAXhb2eUai1yon39E3IsMeta } from "/build/packages/front/pages/roles/[sid]/history.vue?macro=true";
import { default as indexXc6RGYor235aZrgPdAo4i_45vPyYlwEbotnAca2uApNgcMeta } from "/build/packages/front/pages/roles/[sid]/index.vue?macro=true";
import { default as userssbzBdijnFtmXEkfH9ROLU_hbAalxuWUe6xpYUYb_MJsMeta } from "/build/packages/front/pages/roles/[sid]/users.vue?macro=true";
import { default as _91sid_93l1OnLgk8FmEl3VJgDprVY9Dx9n5an_45DUmbqJebZyt0UMeta } from "/build/packages/front/pages/roles/[sid].vue?macro=true";
import { default as indexSpn1WIammoVA7OhBblom_gf7nNNXx37wdXZsvIHoRnwMeta } from "/build/packages/front/pages/roles/index.vue?macro=true";
import { default as searchzPp7jDHDZRytu9aFzyK61LvaSkCCrwKExdr9MbLTi_45IMeta } from "/build/packages/front/pages/search.vue?macro=true";
import { default as basebZnXp5tNlVy2VnAqmJMLp8rO7R2QzUN2YOZceu89k10Meta } from "/build/packages/front/pages/status-models/[sidVer]/base.vue?macro=true";
import { default as crosswaysjgxxg8NbHiLY74SVkEaJLAiYv_45rg99f8uAsXJb6OWVQMeta } from "/build/packages/front/pages/status-models/[sidVer]/crossways.vue?macro=true";
import { default as historyUwjdmVyxBeqPVGegF2PpHb5NIw4RGd94PNiz7Ok9eC8Meta } from "/build/packages/front/pages/status-models/[sidVer]/history.vue?macro=true";
import { default as indexqBnOINT1lhksWIoE1SRs_lZ_45hshNYG0KujonyOkO8TwMeta } from "/build/packages/front/pages/status-models/[sidVer]/index.vue?macro=true";
import { default as map_45list1TuxxVYjdFQbJNRJDuWTUUuR97QFIRgCK4se771nubcMeta } from "/build/packages/front/pages/status-models/[sidVer]/map-list.vue?macro=true";
import { default as status_45set6fgorTu_dIdmT4jCuQAhJRRqJzunW9ReYrkgx4rwmWgMeta } from "/build/packages/front/pages/status-models/[sidVer]/status-set.vue?macro=true";
import { default as _91sidVer_93TfEQvZyWOgTtZhxsOjDvsIaspfLxdbCgUhJ2P_45sIensMeta } from "/build/packages/front/pages/status-models/[sidVer].vue?macro=true";
import { default as indexttiPYxiADlWzFx5mZlynaEgvellJqZcnKEX4YNcNTlAMeta } from "/build/packages/front/pages/status-models/index.vue?macro=true";
import { default as historyOP9soGtRiN2Oawy4q8ObHmYOmNx5kwh7LN5vZa_45dA4sMeta } from "/build/packages/front/pages/system-entities/[sid]/history.vue?macro=true";
import { default as indexqJdDzNlPByt50jTdftm9UMFDx17m0MAnRDfFsu7OeoIMeta } from "/build/packages/front/pages/system-entities/[sid]/index.vue?macro=true";
import { default as status_45modelsKW4GW7Hw5KV7pBuu3Mm0fs_nB3q4wrJ_AUe1bfbprb4Meta } from "/build/packages/front/pages/system-entities/[sid]/status-models.vue?macro=true";
import { default as typesuY5cTOuH6njpvq_45QZWXzoFEG2PpRzf_45AjbvyBW3aIVwMeta } from "/build/packages/front/pages/system-entities/[sid]/types.vue?macro=true";
import { default as _91sid_93pXIlmnzhPurNdIuPgNInVYzxXqOoVyO_45RX43ycaqNpwMeta } from "/build/packages/front/pages/system-entities/[sid].vue?macro=true";
import { default as index4V_UXjH9LTasw1pZjOkGajnABwYsYC9_45Z1wps6uNX_454Meta } from "/build/packages/front/pages/system-entities/index.vue?macro=true";
import { default as history3yvtM4stsOjSlognWk1SjAyRjeGh2rH8vzbSmjfPJdgMeta } from "/build/packages/front/pages/system-fields/[sid]/history.vue?macro=true";
import { default as indexF1zlfcTilwr6inr44RaIGUsOEUBj8vVaN0lI1x32igwMeta } from "/build/packages/front/pages/system-fields/[sid]/index.vue?macro=true";
import { default as _91sid_93w73b9YdapuN80O5HGbsoZY5A4bChHeO0_WN_jQxRD8cMeta } from "/build/packages/front/pages/system-fields/[sid].vue?macro=true";
import { default as indexNpsj_mK5KJDqZquz3pCCUUB6Hto8kz_45G6ovczT9pJIUMeta } from "/build/packages/front/pages/system-fields/index.vue?macro=true";
import { default as historyWMUyVaWNvzsv_45oYdwrAwk4drbFODr4ZVjawIuOW8IVUMeta } from "/build/packages/front/pages/system-tables/[sid]/history.vue?macro=true";
import { default as indexmKnMXdvIzAXJR0hOG2AmZZX4eUtuFLafAxbE1UKaZwoMeta } from "/build/packages/front/pages/system-tables/[sid]/index.vue?macro=true";
import { default as _91sid_93VpBgsGhkp_mlcl173cp1695vh64D9tB7rk_jg01HMqgMeta } from "/build/packages/front/pages/system-tables/[sid].vue?macro=true";
import { default as indexUzYuj8z10aaucl5YrBmP7S5TvhRt5eSPMvqKLB3_2eEMeta } from "/build/packages/front/pages/system-tables/index.vue?macro=true";
import { default as indexejBh6kX0s500wB8xPe_luYwszNctg3sQ5dtXrK9AnJUMeta } from "/build/packages/front/pages/test/[id]/index.vue?macro=true";
import { default as tab19G2b_GfaJRKB26hVIZLvY393ypPPuSslbvGwbq4iA4EMeta } from "/build/packages/front/pages/test/[id]/tab1.vue?macro=true";
import { default as tab22mSsWhkAdv1CQeIzW5pzedH3QWrI_Ki5VomeENdUUmUMeta } from "/build/packages/front/pages/test/[id]/tab2.vue?macro=true";
import { default as _91id_93xgcomzn0TBoOC__459tlQF7jpmcUVtV6f5q8Uee7MDbP0Meta } from "/build/packages/front/pages/test/[id].vue?macro=true";
import { default as import_45fpi7bwX_z7ea_45MsJTb_HokV5vb5iJigJ6omIqjI4LBlv1AMeta } from "/build/packages/front/pages/test/import-fpi.vue?macro=true";
import { default as import_45ingou_45oVcCEC1APB_45nPXBvoBAN3YJcrVNy01ssJkjzXllIMeta } from "/build/packages/front/pages/test/import-ing.vue?macro=true";
import { default as import_45pamZMDyH6_45nrrcCunUQeVkBSmI2OIRFFZA1mSU08g_45AmFoMeta } from "/build/packages/front/pages/test/import-pam.vue?macro=true";
import { default as indexFx7_XyGFMKaxNR5UbpWsL2g06p7kMm6F3O_45LzF6ruaYMeta } from "/build/packages/front/pages/test/index.vue?macro=true";
import { default as index23I9Xr3gDIeUoe7oKTChVSxoh0ZytK7d9BrtTUiGX9kMeta } from "/build/packages/front/pages/testing-methods-systems/[sid]/index.vue?macro=true";
import { default as testing_45methods7Ou3vFJ3xT_45YM30dVV5cag_45qUinA9uWVc4h_ICwzHpUMeta } from "/build/packages/front/pages/testing-methods-systems/[sid]/testing-methods.vue?macro=true";
import { default as _91sid_93OQ1yvui9kKVJ3JgIcvSrtqdeCsV_45_d5OWG9DZvcJU0MMeta } from "/build/packages/front/pages/testing-methods-systems/[sid].vue?macro=true";
import { default as index_455ludtfp7FwTcqevnury44_g_45uVqCB4mm4pntJEYevAMeta } from "/build/packages/front/pages/testing-methods-systems/index.vue?macro=true";
import { default as base_AVJgNCwUwLTUL73qciTNjNT3CaU250udSQWYhkT5ocMeta } from "/build/packages/front/pages/testing-methods/[sidVer]/base.vue?macro=true";
import { default as conditionsWM4jqSCEeql6HVlAwXD6CHpcU8y5zAKZgHwOBu2SsmYMeta } from "/build/packages/front/pages/testing-methods/[sidVer]/conditions.vue?macro=true";
import { default as filesqHqbmyx7Vg2mUCVQq_IB0G_45eVz_3N_FVSUzFP1xMzvIMeta } from "/build/packages/front/pages/testing-methods/[sidVer]/files.vue?macro=true";
import { default as historynJoPjSAHF7DNW6lyv7Tv7_45FeDbrRagH6DoSNieoKcFsMeta } from "/build/packages/front/pages/testing-methods/[sidVer]/history.vue?macro=true";
import { default as indexp7Vl1Hen_UVGOmAM7yH9OcYwPlwbtgixJuHxJY6Y5v4Meta } from "/build/packages/front/pages/testing-methods/[sidVer]/index.vue?macro=true";
import { default as manufacturersMKmzULce5J3opvnpA2yisSTS725MikPOL3cm7LQC_4EMeta } from "/build/packages/front/pages/testing-methods/[sidVer]/manufacturers.vue?macro=true";
import { default as plannerAJxYICOavf0_UADVdnBnh_45OkUlmFFYxtKGu_45DOe06AMMeta } from "/build/packages/front/pages/testing-methods/[sidVer]/planner.vue?macro=true";
import { default as versionsvYJFFE1yZwBIam_KSxbN_nvhceyyGF5i28WkE6jhVGkMeta } from "/build/packages/front/pages/testing-methods/[sidVer]/versions.vue?macro=true";
import { default as _91sidVer_93LGF7mzG0SmLuz3PG8iO4dDS2lx8f3CyXcrhITlEvKUsMeta } from "/build/packages/front/pages/testing-methods/[sidVer].vue?macro=true";
import { default as indexHwr3NilS1HmwjL8Kys16q_45wK4bcC1u_45ewvx1WJGApk4Meta } from "/build/packages/front/pages/testing-methods/index.vue?macro=true";
import { default as historyvTqa_uC4t8NKXqbgzpfUZ7RthB5kDKWvoFnZY5JTkjEMeta } from "/build/packages/front/pages/translations/[sid]/history.vue?macro=true";
import { default as indexpPL_sMMX_45f0KQVXOdYRq81hwrFLJqMYA_45pvVgUEgxzQMeta } from "/build/packages/front/pages/translations/[sid]/index.vue?macro=true";
import { default as test_45pluralP0KdK8zFIds1DLf5NhJEW37m8bdUMvcl7o8vxFq_45vYAMeta } from "/build/packages/front/pages/translations/[sid]/test-plural.vue?macro=true";
import { default as _91sid_93gV_45jreTy1Mn9BwmP7yHQbb6buHM2M75gr_6L7VQ16qkMeta } from "/build/packages/front/pages/translations/[sid].vue?macro=true";
import { default as importlcEt5QjizojQ9qswct8TkVSdkdGOmyOGnBd94Go_3ccMeta } from "/build/packages/front/pages/translations/import.vue?macro=true";
import { default as index_E8dhgGEhZmOYnvkFayheoOhwX9K8et9vvg23hFZUFkMeta } from "/build/packages/front/pages/translations/index.vue?macro=true";
import { default as colorPalette58Fw_tgWmDBzYyY4E8pNcmWslCzK_45szcQSdIOFRZli4Meta } from "/build/packages/front/pages/ui/colorPalette.vue?macro=true";
import { default as dialogdDqurlZSMvb_prM0Klj4o56xfNIPl4ohdGoBOz1pv7AMeta } from "/build/packages/front/pages/ui/dialog.vue?macro=true";
import { default as formUuSDkHrzc2aqdSw8aBLaWOd98W4tST6cPIyYJCxHVMIMeta } from "/build/packages/front/pages/ui/form.vue?macro=true";
import { default as icon6Pq3Y_XpBrkEVFTvuaPTp9STqi2b7no5_xMafqYC1_45wMeta } from "/build/packages/front/pages/ui/icon.vue?macro=true";
import { default as indexwY5zylaRB2qD8qf_2Mtcz68SOqxzKIS3Ro6ALnutYXsMeta } from "/build/packages/front/pages/ui/index.vue?macro=true";
import { default as listMDeLuO7f2P39U2ECjyD04jzkBmyPM9U0D1ejvG6EMRYMeta } from "/build/packages/front/pages/ui/list.vue?macro=true";
import { default as messageHtHbFfhuUUDrqVu43RE21uxliRqHDhMdTI_b2ujQ2OkMeta } from "/build/packages/front/pages/ui/message.vue?macro=true";
import { default as treev17Gd9cpFq3l0SwKhIKkD7mjHRqm67qTC_45p_45lk4lW54Meta } from "/build/packages/front/pages/ui/tree.vue?macro=true";
import { default as value_45view_45formatter_45qNfKNjIkkS38fG8bPJBGX8sGDbFBUCE8mqF6LrFiEoMeta } from "/build/packages/front/pages/ui/value-view-formatter.vue?macro=true";
import { default as virtual_45scrollIqGPb6eozj_45zqZIuluBp5SjwtYO4eWb_45yKjePg0s_45NcMeta } from "/build/packages/front/pages/ui/virtual-scroll.vue?macro=true";
import { default as historyS1jIKlK6A4T5ZMZMK43qp0m0orMKL0CJGYPPMiAYPykMeta } from "/build/packages/front/pages/units-groups/[sid]/history.vue?macro=true";
import { default as indexY_459kItwLzIWXF2_iAx7ftqY2FDQM8_OgI77_45z4ZPeRsMeta } from "/build/packages/front/pages/units-groups/[sid]/index.vue?macro=true";
import { default as unitsC947qTjPVUiS4s7nQibSAZ9KszAzPoC0sNu3348MpCAMeta } from "/build/packages/front/pages/units-groups/[sid]/units.vue?macro=true";
import { default as _91sid_93iVL22_45MvVvuxTk6ST8C9DDaYW7ylIbQYrMJn6AwuZ78Meta } from "/build/packages/front/pages/units-groups/[sid].vue?macro=true";
import { default as indexwW_dWaq8EACH1xmPgL7avjM_45nnYSoZJ_45RPF6W0TmducMeta } from "/build/packages/front/pages/units-groups/index.vue?macro=true";
import { default as historylPq0BVA_45ue9Jv4FhKl5GQPNLpi3Nipjr9HM4Bgd8948Meta } from "/build/packages/front/pages/units/[sid]/history.vue?macro=true";
import { default as indexjvGz1pXr18yR__QwLxDdiuwRdt9J6S1jxXdov4VngdgMeta } from "/build/packages/front/pages/units/[sid]/index.vue?macro=true";
import { default as _91sid_93Z2Y8pqV1i9zekvZctiB_45YbLDsJU_N663DeK7UPUUsk4Meta } from "/build/packages/front/pages/units/[sid].vue?macro=true";
import { default as indexUyAHaIQNlMnydZ_45mYnb6Sc5980RloT3to6_45lf8kt_PUMeta } from "/build/packages/front/pages/units/index.vue?macro=true";
import { default as historyTnfyh9gWTJBPL8smSUy4XgiB9pBeIWKLhJVw8QdA8ZsMeta } from "/build/packages/front/pages/users/[sid]/history.vue?macro=true";
import { default as indexyHSG3bZ2zbxTpTQ_gDmUfscu5_4550AtBt6RaYDHpAYWoMeta } from "/build/packages/front/pages/users/[sid]/index.vue?macro=true";
import { default as rolescXJkn4JpOZu0ZmnOQERZvLdR8J_gnd0fkEcdPFcqQXMMeta } from "/build/packages/front/pages/users/[sid]/roles.vue?macro=true";
import { default as workgroupsfI6n0VmIo2zG4Ep_ULfXLGGULjptOLFyoWPoElUnTwcMeta } from "/build/packages/front/pages/users/[sid]/workgroups.vue?macro=true";
import { default as _91sid_93uCG9Kt9uaFjo_45t0ngJUloQp83vtXsCl_45xHNKB2WvijoMeta } from "/build/packages/front/pages/users/[sid].vue?macro=true";
import { default as index3W8XesOLWJqumBiC00lxTNkmWC5ae6h8zR4sJOkaD5wMeta } from "/build/packages/front/pages/users/index.vue?macro=true";
import { default as historyEmpZjTrlnAT0ZACv6DQK_45vbmZ0dXgUpaMkF2PNdGYQ0Meta } from "/build/packages/front/pages/workgroups/[sid]/history.vue?macro=true";
import { default as indexYPonzZPPW872sQPsgNLcy7hSgWXSK1fMEyG_LOEKs5YMeta } from "/build/packages/front/pages/workgroups/[sid]/index.vue?macro=true";
import { default as usersrFvda9YKc3zsGJoWMB3CuF32oQOXQArJHBCFdyFeMV4Meta } from "/build/packages/front/pages/workgroups/[sid]/users.vue?macro=true";
import { default as _91sid_93NKYRl6MBrCod1Uy9JLkxzaIk8UmA_4kG4RM_x_452SbSQMeta } from "/build/packages/front/pages/workgroups/[sid].vue?macro=true";
import { default as indexNvBhRETZpQjUzjrt1HdSh_45H11VzIcyikdzXOJJpxrCMMeta } from "/build/packages/front/pages/workgroups/index.vue?macro=true";
export default [
  {
    name: _91sid_93DVtEDBbG9sayJScDPiaLbcdqegoFp9NOTCqCwBlApJIMeta?.name,
    path: "/abilities/:sid()",
    component: () => import("/build/packages/front/pages/abilities/[sid].vue"),
    children: [
  {
    name: "abilities-sid-hierarchy",
    path: "hierarchy",
    component: () => import("/build/packages/front/pages/abilities/[sid]/hierarchy.vue")
  },
  {
    name: "abilities-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/abilities/[sid]/history.vue")
  },
  {
    name: "abilities-sid",
    path: "",
    component: () => import("/build/packages/front/pages/abilities/[sid]/index.vue")
  }
]
  },
  {
    name: "abilities",
    path: "/abilities",
    component: () => import("/build/packages/front/pages/abilities/index.vue")
  },
  {
    name: "auth-error",
    path: "/auth-error",
    meta: auth_45errorNZVdke_45Zl49ST91FbBOVK06f8QEvd0AkVzuug988feAMeta || {},
    component: () => import("/build/packages/front/pages/auth-error.vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: authpCDSlZRO5_bAl2TcA9mzE9AmwKMLRr5W_rNLQ_45BIa30Meta || {},
    component: () => import("/build/packages/front/pages/auth.vue")
  },
  {
    name: _91sid_93_45ZuK2F7xGRB_45UMLmPnf87ONFdVV_Aup3P0nxxGPzUP4Meta?.name,
    path: "/business-statuses/:sid()",
    component: () => import("/build/packages/front/pages/business-statuses/[sid].vue"),
    children: [
  {
    name: "business-statuses-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/business-statuses/[sid]/history.vue")
  },
  {
    name: "business-statuses-sid",
    path: "",
    component: () => import("/build/packages/front/pages/business-statuses/[sid]/index.vue")
  }
]
  },
  {
    name: "business-statuses",
    path: "/business-statuses",
    component: () => import("/build/packages/front/pages/business-statuses/index.vue")
  },
  {
    name: _91sid_93RmItL76NPLdcTB4VejCJsKgDbmhBi9FQcufXNtbzwPIMeta?.name,
    path: "/characteristics-field-names/:sid()",
    component: () => import("/build/packages/front/pages/characteristics-field-names/[sid].vue"),
    children: [
  {
    name: "characteristics-field-names-sid-characteristics",
    path: "characteristics",
    component: () => import("/build/packages/front/pages/characteristics-field-names/[sid]/characteristics.vue")
  },
  {
    name: "characteristics-field-names-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/characteristics-field-names/[sid]/history.vue")
  },
  {
    name: "characteristics-field-names-sid",
    path: "",
    component: () => import("/build/packages/front/pages/characteristics-field-names/[sid]/index.vue")
  }
]
  },
  {
    name: "characteristics-field-names",
    path: "/characteristics-field-names",
    component: () => import("/build/packages/front/pages/characteristics-field-names/index.vue")
  },
  {
    name: _91sid_93fJEYqL_45kG_zkgt46vt5VKf27ujCWMO46XPbhGLYNkVIMeta?.name,
    path: "/characteristics-groups/:sid()",
    component: () => import("/build/packages/front/pages/characteristics-groups/[sid].vue"),
    children: [
  {
    name: "characteristics-groups-sid-characteristics",
    path: "characteristics",
    component: () => import("/build/packages/front/pages/characteristics-groups/[sid]/characteristics.vue")
  },
  {
    name: "characteristics-groups-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/characteristics-groups/[sid]/history.vue")
  },
  {
    name: "characteristics-groups-sid",
    path: "",
    component: () => import("/build/packages/front/pages/characteristics-groups/[sid]/index.vue")
  }
]
  },
  {
    name: "characteristics-groups",
    path: "/characteristics-groups",
    component: () => import("/build/packages/front/pages/characteristics-groups/index.vue")
  },
  {
    name: _91sid_93F8N4BAayfzqnbQHM6ThSqZE932_459sXw7YqeKyrIYHE0Meta?.name,
    path: "/characteristics/:sid()",
    component: () => import("/build/packages/front/pages/characteristics/[sid].vue"),
    children: [
  {
    name: "characteristics-sid-data-sources",
    path: "data-sources",
    component: () => import("/build/packages/front/pages/characteristics/[sid]/data-sources.vue")
  },
  {
    name: "characteristics-sid-fields",
    path: "fields",
    component: () => import("/build/packages/front/pages/characteristics/[sid]/fields.vue")
  },
  {
    name: "characteristics-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/characteristics/[sid]/history.vue")
  },
  {
    name: "characteristics-sid",
    path: "",
    component: () => import("/build/packages/front/pages/characteristics/[sid]/index.vue")
  }
]
  },
  {
    name: "characteristics",
    path: "/characteristics",
    component: () => import("/build/packages/front/pages/characteristics/index.vue")
  },
  {
    name: _91sid_93RTgIYJ1A_BZevCa_451IH7dvRU1thJZWQEgOIhn2_453P9cMeta?.name,
    path: "/countries/:sid()",
    component: () => import("/build/packages/front/pages/countries/[sid].vue"),
    children: [
  {
    name: "countries-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/countries/[sid]/history.vue")
  },
  {
    name: "countries-sid",
    path: "",
    component: () => import("/build/packages/front/pages/countries/[sid]/index.vue")
  }
]
  },
  {
    name: "countries",
    path: "/countries",
    component: () => import("/build/packages/front/pages/countries/index.vue")
  },
  {
    name: _91sid_93MvcEvsNYKFkeL6S0ixCp0xXpW_wo70AYwz9ocx_45yTiEMeta?.name,
    path: "/currencies/:sid()",
    component: () => import("/build/packages/front/pages/currencies/[sid].vue"),
    children: [
  {
    name: "currencies-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/currencies/[sid]/history.vue")
  },
  {
    name: "currencies-sid",
    path: "",
    component: () => import("/build/packages/front/pages/currencies/[sid]/index.vue")
  }
]
  },
  {
    name: "currencies",
    path: "/currencies",
    component: () => import("/build/packages/front/pages/currencies/index.vue")
  },
  {
    name: "dev",
    path: "/dev",
    component: () => import("/build/packages/front/pages/dev/index.vue")
  },
  {
    name: _91sid_93ZHAA79Aq5Ns798rq5bL9rPgxp_45blggcriYdur_pJyjQMeta?.name,
    path: "/documents/:sid()",
    component: () => import("/build/packages/front/pages/documents/[sid].vue"),
    children: [
  {
    name: "documents-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/documents/[sid]/history.vue")
  },
  {
    name: "documents-sid",
    path: "",
    component: () => import("/build/packages/front/pages/documents/[sid]/index.vue")
  }
]
  },
  {
    name: "documents",
    path: "/documents",
    component: () => import("/build/packages/front/pages/documents/index.vue")
  },
  {
    name: _91sid_93g23rR6t4FNGTXV7cBBnFbbcPka3ygQS5IcIGUlfsELIMeta?.name,
    path: "/files/:sid()",
    component: () => import("/build/packages/front/pages/files/[sid].vue"),
    children: [
  {
    name: "files-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/files/[sid]/history.vue")
  },
  {
    name: "files-sid",
    path: "",
    component: () => import("/build/packages/front/pages/files/[sid]/index.vue")
  }
]
  },
  {
    name: "files",
    path: "/files",
    component: () => import("/build/packages/front/pages/files/index.vue")
  },
  {
    name: "history",
    path: "/history",
    component: () => import("/build/packages/front/pages/history.vue")
  },
  {
    name: "id",
    path: "/id",
    component: () => import("/build/packages/front/pages/id.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indextSvbk_GEujLBszfovM7qvHubN9rWY_45jfhDcu6d78o4UMeta || {},
    component: () => import("/build/packages/front/pages/index.vue")
  },
  {
    name: _91sid_93bkZFSjSV7tSJsAaR9d45SOT1m5EWTKFkXiME2p_LrmAMeta?.name,
    path: "/languages/:sid()",
    component: () => import("/build/packages/front/pages/languages/[sid].vue"),
    children: [
  {
    name: "languages-sid-detail",
    path: "detail",
    component: () => import("/build/packages/front/pages/languages/[sid]/detail.vue")
  },
  {
    name: "languages-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/languages/[sid]/history.vue")
  },
  {
    name: "languages-sid",
    path: "",
    component: () => import("/build/packages/front/pages/languages/[sid]/index.vue")
  },
  {
    name: "languages-sid-test",
    path: "test",
    component: () => import("/build/packages/front/pages/languages/[sid]/test.vue")
  }
]
  },
  {
    name: "languages",
    path: "/languages",
    component: () => import("/build/packages/front/pages/languages/index.vue")
  },
  {
    name: _91sid_93_45Eez5ch7ux31xr92y8ZeLLu0W1b_45g1BYMQ0dsvje7DMMeta?.name,
    path: "/manufacturers/:sid()",
    component: () => import("/build/packages/front/pages/manufacturers/[sid].vue"),
    children: [
  {
    name: "manufacturers-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/manufacturers/[sid]/history.vue")
  },
  {
    name: "manufacturers-sid",
    path: "",
    component: () => import("/build/packages/front/pages/manufacturers/[sid]/index.vue")
  }
]
  },
  {
    name: "manufacturers",
    path: "/manufacturers",
    component: () => import("/build/packages/front/pages/manufacturers/index.vue")
  },
  {
    name: _91sid_939vH5FTVgzf05ar06O_45RxvE_2pnLUhKy2j1zVX_9EWcoMeta?.name,
    path: "/materials-classes/:sid()",
    component: () => import("/build/packages/front/pages/materials-classes/[sid].vue"),
    children: [
  {
    name: "materials-classes-sid-descriptor",
    path: "descriptor",
    component: () => import("/build/packages/front/pages/materials-classes/[sid]/descriptor.vue")
  },
  {
    name: "materials-classes-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/materials-classes/[sid]/history.vue")
  },
  {
    name: "materials-classes-sid",
    path: "",
    component: () => import("/build/packages/front/pages/materials-classes/[sid]/index.vue")
  }
]
  },
  {
    name: "materials-classes",
    path: "/materials-classes",
    component: () => import("/build/packages/front/pages/materials-classes/index.vue")
  },
  {
    name: _91sid_93JVg4oc_y_ZsAs49gHCljEhys_45YkakZdm7mDjWMr5u9gMeta?.name,
    path: "/materials-types/:sid()",
    component: () => import("/build/packages/front/pages/materials-types/[sid].vue"),
    children: [
  {
    name: "materials-types-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/materials-types/[sid]/history.vue")
  },
  {
    name: "materials-types-sid",
    path: "",
    component: () => import("/build/packages/front/pages/materials-types/[sid]/index.vue")
  }
]
  },
  {
    name: "materials-types",
    path: "/materials-types",
    component: () => import("/build/packages/front/pages/materials-types/index.vue")
  },
  {
    name: _91sid_93Hb1xxFMc_45i71OAsTakVUCfThzE9fXtjM2ljhKR_45iCqEMeta?.name,
    path: "/references/:sid()",
    component: () => import("/build/packages/front/pages/references/[sid].vue"),
    children: [
  {
    name: "references-sid-fields",
    path: "fields",
    component: () => import("/build/packages/front/pages/references/[sid]/fields.vue")
  },
  {
    name: "references-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/references/[sid]/history.vue")
  },
  {
    name: "references-sid",
    path: "",
    component: () => import("/build/packages/front/pages/references/[sid]/index.vue")
  },
  {
    name: "references-sid-records",
    path: "records",
    component: () => import("/build/packages/front/pages/references/[sid]/records.vue")
  }
]
  },
  {
    name: "references",
    path: "/references",
    component: () => import("/build/packages/front/pages/references/index.vue")
  },
  {
    name: _91sid_93l1OnLgk8FmEl3VJgDprVY9Dx9n5an_45DUmbqJebZyt0UMeta?.name,
    path: "/roles/:sid()",
    component: () => import("/build/packages/front/pages/roles/[sid].vue"),
    children: [
  {
    name: "roles-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/roles/[sid]/history.vue")
  },
  {
    name: "roles-sid",
    path: "",
    component: () => import("/build/packages/front/pages/roles/[sid]/index.vue")
  },
  {
    name: "roles-sid-users",
    path: "users",
    component: () => import("/build/packages/front/pages/roles/[sid]/users.vue")
  }
]
  },
  {
    name: "roles",
    path: "/roles",
    component: () => import("/build/packages/front/pages/roles/index.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/build/packages/front/pages/search.vue")
  },
  {
    name: _91sidVer_93TfEQvZyWOgTtZhxsOjDvsIaspfLxdbCgUhJ2P_45sIensMeta?.name,
    path: "/status-models/:sidVer()",
    component: () => import("/build/packages/front/pages/status-models/[sidVer].vue"),
    children: [
  {
    name: "status-models-sidVer-base",
    path: "base",
    component: () => import("/build/packages/front/pages/status-models/[sidVer]/base.vue")
  },
  {
    name: "status-models-sidVer-crossways",
    path: "crossways",
    component: () => import("/build/packages/front/pages/status-models/[sidVer]/crossways.vue")
  },
  {
    name: "status-models-sidVer-history",
    path: "history",
    component: () => import("/build/packages/front/pages/status-models/[sidVer]/history.vue")
  },
  {
    name: "status-models-sidVer",
    path: "",
    component: () => import("/build/packages/front/pages/status-models/[sidVer]/index.vue")
  },
  {
    name: "status-models-sidVer-map-list",
    path: "map-list",
    component: () => import("/build/packages/front/pages/status-models/[sidVer]/map-list.vue")
  },
  {
    name: "status-models-sidVer-status-set",
    path: "status-set",
    component: () => import("/build/packages/front/pages/status-models/[sidVer]/status-set.vue")
  }
]
  },
  {
    name: "status-models",
    path: "/status-models",
    component: () => import("/build/packages/front/pages/status-models/index.vue")
  },
  {
    name: _91sid_93pXIlmnzhPurNdIuPgNInVYzxXqOoVyO_45RX43ycaqNpwMeta?.name,
    path: "/system-entities/:sid()",
    component: () => import("/build/packages/front/pages/system-entities/[sid].vue"),
    children: [
  {
    name: "system-entities-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/system-entities/[sid]/history.vue")
  },
  {
    name: "system-entities-sid",
    path: "",
    component: () => import("/build/packages/front/pages/system-entities/[sid]/index.vue")
  },
  {
    name: "system-entities-sid-status-models",
    path: "status-models",
    component: () => import("/build/packages/front/pages/system-entities/[sid]/status-models.vue")
  },
  {
    name: "system-entities-sid-types",
    path: "types",
    component: () => import("/build/packages/front/pages/system-entities/[sid]/types.vue")
  }
]
  },
  {
    name: "system-entities",
    path: "/system-entities",
    component: () => import("/build/packages/front/pages/system-entities/index.vue")
  },
  {
    name: _91sid_93w73b9YdapuN80O5HGbsoZY5A4bChHeO0_WN_jQxRD8cMeta?.name,
    path: "/system-fields/:sid()",
    component: () => import("/build/packages/front/pages/system-fields/[sid].vue"),
    children: [
  {
    name: "system-fields-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/system-fields/[sid]/history.vue")
  },
  {
    name: "system-fields-sid",
    path: "",
    component: () => import("/build/packages/front/pages/system-fields/[sid]/index.vue")
  }
]
  },
  {
    name: "system-fields",
    path: "/system-fields",
    component: () => import("/build/packages/front/pages/system-fields/index.vue")
  },
  {
    name: _91sid_93VpBgsGhkp_mlcl173cp1695vh64D9tB7rk_jg01HMqgMeta?.name,
    path: "/system-tables/:sid()",
    component: () => import("/build/packages/front/pages/system-tables/[sid].vue"),
    children: [
  {
    name: "system-tables-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/system-tables/[sid]/history.vue")
  },
  {
    name: "system-tables-sid",
    path: "",
    component: () => import("/build/packages/front/pages/system-tables/[sid]/index.vue")
  }
]
  },
  {
    name: "system-tables",
    path: "/system-tables",
    component: () => import("/build/packages/front/pages/system-tables/index.vue")
  },
  {
    name: _91id_93xgcomzn0TBoOC__459tlQF7jpmcUVtV6f5q8Uee7MDbP0Meta?.name,
    path: "/test/:id()",
    component: () => import("/build/packages/front/pages/test/[id].vue"),
    children: [
  {
    name: "test-id",
    path: "",
    component: () => import("/build/packages/front/pages/test/[id]/index.vue")
  },
  {
    name: "test-id-tab1",
    path: "tab1",
    component: () => import("/build/packages/front/pages/test/[id]/tab1.vue")
  },
  {
    name: "test-id-tab2",
    path: "tab2",
    component: () => import("/build/packages/front/pages/test/[id]/tab2.vue")
  }
]
  },
  {
    name: "test-import-fpi",
    path: "/test/import-fpi",
    component: () => import("/build/packages/front/pages/test/import-fpi.vue")
  },
  {
    name: "test-import-ing",
    path: "/test/import-ing",
    component: () => import("/build/packages/front/pages/test/import-ing.vue")
  },
  {
    name: "test-import-pam",
    path: "/test/import-pam",
    component: () => import("/build/packages/front/pages/test/import-pam.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/build/packages/front/pages/test/index.vue")
  },
  {
    name: _91sid_93OQ1yvui9kKVJ3JgIcvSrtqdeCsV_45_d5OWG9DZvcJU0MMeta?.name,
    path: "/testing-methods-systems/:sid()",
    component: () => import("/build/packages/front/pages/testing-methods-systems/[sid].vue"),
    children: [
  {
    name: "testing-methods-systems-sid",
    path: "",
    component: () => import("/build/packages/front/pages/testing-methods-systems/[sid]/index.vue")
  },
  {
    name: "testing-methods-systems-sid-testing-methods",
    path: "testing-methods",
    component: () => import("/build/packages/front/pages/testing-methods-systems/[sid]/testing-methods.vue")
  }
]
  },
  {
    name: "testing-methods-systems",
    path: "/testing-methods-systems",
    component: () => import("/build/packages/front/pages/testing-methods-systems/index.vue")
  },
  {
    name: _91sidVer_93LGF7mzG0SmLuz3PG8iO4dDS2lx8f3CyXcrhITlEvKUsMeta?.name,
    path: "/testing-methods/:sidVer()",
    component: () => import("/build/packages/front/pages/testing-methods/[sidVer].vue"),
    children: [
  {
    name: "testing-methods-sidVer-base",
    path: "base",
    component: () => import("/build/packages/front/pages/testing-methods/[sidVer]/base.vue")
  },
  {
    name: "testing-methods-sidVer-conditions",
    path: "conditions",
    component: () => import("/build/packages/front/pages/testing-methods/[sidVer]/conditions.vue")
  },
  {
    name: "testing-methods-sidVer-files",
    path: "files",
    component: () => import("/build/packages/front/pages/testing-methods/[sidVer]/files.vue")
  },
  {
    name: "testing-methods-sidVer-history",
    path: "history",
    component: () => import("/build/packages/front/pages/testing-methods/[sidVer]/history.vue")
  },
  {
    name: "testing-methods-sidVer",
    path: "",
    component: () => import("/build/packages/front/pages/testing-methods/[sidVer]/index.vue")
  },
  {
    name: "testing-methods-sidVer-manufacturers",
    path: "manufacturers",
    component: () => import("/build/packages/front/pages/testing-methods/[sidVer]/manufacturers.vue")
  },
  {
    name: "testing-methods-sidVer-planner",
    path: "planner",
    component: () => import("/build/packages/front/pages/testing-methods/[sidVer]/planner.vue")
  },
  {
    name: "testing-methods-sidVer-versions",
    path: "versions",
    component: () => import("/build/packages/front/pages/testing-methods/[sidVer]/versions.vue")
  }
]
  },
  {
    name: "testing-methods",
    path: "/testing-methods",
    component: () => import("/build/packages/front/pages/testing-methods/index.vue")
  },
  {
    name: _91sid_93gV_45jreTy1Mn9BwmP7yHQbb6buHM2M75gr_6L7VQ16qkMeta?.name,
    path: "/translations/:sid()",
    component: () => import("/build/packages/front/pages/translations/[sid].vue"),
    children: [
  {
    name: "translations-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/translations/[sid]/history.vue")
  },
  {
    name: "translations-sid",
    path: "",
    component: () => import("/build/packages/front/pages/translations/[sid]/index.vue")
  },
  {
    name: "translations-sid-test-plural",
    path: "test-plural",
    component: () => import("/build/packages/front/pages/translations/[sid]/test-plural.vue")
  }
]
  },
  {
    name: "translations-import",
    path: "/translations/import",
    component: () => import("/build/packages/front/pages/translations/import.vue")
  },
  {
    name: "translations",
    path: "/translations",
    component: () => import("/build/packages/front/pages/translations/index.vue")
  },
  {
    name: "ui-colorPalette",
    path: "/ui/colorPalette",
    component: () => import("/build/packages/front/pages/ui/colorPalette.vue")
  },
  {
    name: "ui-dialog",
    path: "/ui/dialog",
    component: () => import("/build/packages/front/pages/ui/dialog.vue")
  },
  {
    name: "ui-form",
    path: "/ui/form",
    component: () => import("/build/packages/front/pages/ui/form.vue")
  },
  {
    name: "ui-icon",
    path: "/ui/icon",
    component: () => import("/build/packages/front/pages/ui/icon.vue")
  },
  {
    name: "ui",
    path: "/ui",
    component: () => import("/build/packages/front/pages/ui/index.vue")
  },
  {
    name: "ui-list",
    path: "/ui/list",
    component: () => import("/build/packages/front/pages/ui/list.vue")
  },
  {
    name: "ui-message",
    path: "/ui/message",
    component: () => import("/build/packages/front/pages/ui/message.vue")
  },
  {
    name: "ui-tree",
    path: "/ui/tree",
    component: () => import("/build/packages/front/pages/ui/tree.vue")
  },
  {
    name: "ui-value-view-formatter",
    path: "/ui/value-view-formatter",
    component: () => import("/build/packages/front/pages/ui/value-view-formatter.vue")
  },
  {
    name: "ui-virtual-scroll",
    path: "/ui/virtual-scroll",
    component: () => import("/build/packages/front/pages/ui/virtual-scroll.vue")
  },
  {
    name: _91sid_93iVL22_45MvVvuxTk6ST8C9DDaYW7ylIbQYrMJn6AwuZ78Meta?.name,
    path: "/units-groups/:sid()",
    component: () => import("/build/packages/front/pages/units-groups/[sid].vue"),
    children: [
  {
    name: "units-groups-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/units-groups/[sid]/history.vue")
  },
  {
    name: "units-groups-sid",
    path: "",
    component: () => import("/build/packages/front/pages/units-groups/[sid]/index.vue")
  },
  {
    name: "units-groups-sid-units",
    path: "units",
    component: () => import("/build/packages/front/pages/units-groups/[sid]/units.vue")
  }
]
  },
  {
    name: "units-groups",
    path: "/units-groups",
    component: () => import("/build/packages/front/pages/units-groups/index.vue")
  },
  {
    name: _91sid_93Z2Y8pqV1i9zekvZctiB_45YbLDsJU_N663DeK7UPUUsk4Meta?.name,
    path: "/units/:sid()",
    component: () => import("/build/packages/front/pages/units/[sid].vue"),
    children: [
  {
    name: "units-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/units/[sid]/history.vue")
  },
  {
    name: "units-sid",
    path: "",
    component: () => import("/build/packages/front/pages/units/[sid]/index.vue")
  }
]
  },
  {
    name: "units",
    path: "/units",
    component: () => import("/build/packages/front/pages/units/index.vue")
  },
  {
    name: _91sid_93uCG9Kt9uaFjo_45t0ngJUloQp83vtXsCl_45xHNKB2WvijoMeta?.name,
    path: "/users/:sid()",
    component: () => import("/build/packages/front/pages/users/[sid].vue"),
    children: [
  {
    name: "users-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/users/[sid]/history.vue")
  },
  {
    name: "users-sid",
    path: "",
    component: () => import("/build/packages/front/pages/users/[sid]/index.vue")
  },
  {
    name: "users-sid-roles",
    path: "roles",
    component: () => import("/build/packages/front/pages/users/[sid]/roles.vue")
  },
  {
    name: "users-sid-workgroups",
    path: "workgroups",
    component: () => import("/build/packages/front/pages/users/[sid]/workgroups.vue")
  }
]
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/build/packages/front/pages/users/index.vue")
  },
  {
    name: _91sid_93NKYRl6MBrCod1Uy9JLkxzaIk8UmA_4kG4RM_x_452SbSQMeta?.name,
    path: "/workgroups/:sid()",
    component: () => import("/build/packages/front/pages/workgroups/[sid].vue"),
    children: [
  {
    name: "workgroups-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/workgroups/[sid]/history.vue")
  },
  {
    name: "workgroups-sid",
    path: "",
    component: () => import("/build/packages/front/pages/workgroups/[sid]/index.vue")
  },
  {
    name: "workgroups-sid-users",
    path: "users",
    component: () => import("/build/packages/front/pages/workgroups/[sid]/users.vue")
  }
]
  },
  {
    name: "workgroups",
    path: "/workgroups",
    component: () => import("/build/packages/front/pages/workgroups/index.vue")
  }
]