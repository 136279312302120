<template>
    <span class="abilities-tooltip--wrap">
        <a
            v-for="i in abilities"
            :key="i.sid"
            :href="`/abilities/${i.sid}`"
            :title="i.name[lang]"
            class="abilities-tooltip--item"
            :class="{ 'abilities-tooltip--item-target': isTarget }"
            @click.prevent="goToLink"
        >{{ labelBySid(i.sid) }}</a>
    </span>
</template>

<script lang="ts" setup>
import type { NuxtApp } from '#app'
import { useAbilityImpact } from '~/components/Abilities/useAbilityImpact'

const props = defineProps<{ abilities: { sid: string, name: string }[], isTarget: boolean, app: NuxtApp }>()
const { labelBySid } = useAbilityImpact()
const lang = props.app.$i18next.language

function goToLink(event: MouseEvent) {
    props.app.$router.push((event.target as HTMLElement).getAttribute('href') as string)
}
</script>

<style>
.ability-target--item {
    box-shadow: 0 0 5px 1px var(--red);
}

.abilities-tooltip--item.abilities-tooltip--item-target {
    background-color: var(--red);
    color: white;
    &:before {
        border-top: 6px solid var(--red);
    }
}

.abilities-tooltip--wrap {
    position: absolute;
    z-index: 10;
    display: flex;
    gap: 5px;
    bottom: 100%;
    left: 0;
}

.abilities-tooltip--item {
    position: relative;
    background-color: var(--yellow-a);
    padding: 2px 4px;
    border-radius: 6px;
    margin-bottom: 0;
    color: black;
    font-size: var(--font-size-small);
    line-height: 1em;

    &:before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-top: 6px solid var(--yellow-a);
        top: 100%;
        left: 4px;
    }
}
</style>
