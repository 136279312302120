<template>
    <div class="m-8">
        <RLoading v-if="error?.message === 'SoftReload'" />
        <div v-else-if="error?.statusCode === 502">
            <h1>⛔ Нет связи с сервером</h1>
            <hr class="my-8">
            <div class="flex gap-8">
                <RBtn href="#" @click="clearError()">Обновить</RBtn>
                <RBtn href="#" @click="router.go(-1)">Вернуться назад</RBtn>
                <RBtn href="#" @click="router.replace('/')">Перейти на главную</RBtn>
            </div>
        </div>
        <div v-else-if="error?.statusCode === 404">
            <h1>⛔ Запись не найдена</h1>
            <hr class="my-8">
            <pre class="font-color-extra-light">{{ error }}</pre>
            <hr class="my-8">
            <div class="flex gap-8">
                <RBtn href="#" @click="router.go(-1)">Вернуться назад</RBtn>
                <RBtn href="#" @click="router.replace('/')">Перейти на главную</RBtn>
            </div>
        </div>
        <div v-else-if="error?.statusCode === 422">
            <h2>⛔ Ошибка валидации</h2>
            <hr class="my-8">
            <div class="flex gap-8">
                <RBtn href="#" @click="clearError()">Обновить</RBtn>
            </div>
            <hr class="my-8">
            <JsonViewer :val="error.data" />
        </div>
        <div v-else>
            <h1>⛔ Произошла ошибка <span class="text-gray-300">({{ error?.statusCode }})</span></h1>
            Попробуйте повторить действие через 5 минут.
            <hr class="my-8">
            <pre>{{ error }}</pre>
            <hr class="my-8">
            <div class="flex gap-8">
                <RBtn href="#" @click="clearError()">Обновить</RBtn>
                <RBtn href="#" @click="router.go(-1)">Вернуться назад</RBtn>
                <RBtn href="#" @click="router.replace('/')">Перейти на главную</RBtn>
            </div>
        </div>
    </div>
</template>

<script setup>
import { clearError, useError } from '#app'
import { useRouter } from '#imports'
import { unref } from 'vue'

const router = useRouter()
const error = unref(useError())
</script>
