import type { UserSettingsUpdate } from 'back/src/services/User'
import type { ExponentialView, FractionSeparator, SegmentsSeparator } from '~/components/ValueViewFormatter/types'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useFetchCall } from '~/composables/useFetchCall'

export interface UserSettings {
    langUi: string
    langData: string
    segSep: SegmentsSeparator
    fracSep: FractionSeparator
    mitFlag: boolean
    expView: ExponentialView
    themeColor: UserSettingsUpdate['themeColor']
}

export interface UserStore {
    sid: number
    userId: string
    email: string
    name: string
    settings: UserSettings
    abilities: Set<number>
}

const updating = ref(false)

export const useUserStore = defineStore('user', {
    state: (): UserStore => ({
        sid: 0,
        userId: '',
        email: '',
        name: '',

        settings: {
            langUi: '',
            langData: '',
            segSep: 'unset',
            fracSep: 'comma',
            mitFlag: false,
            expView: 'regular',
            themeColor: 'blue',
        },

        abilities: new Set(),
    }),
    getters: {
        updating() {
            return updating.value
        },
        can: (state: UserStore) => (abilitySid: string | number) => state.abilities.has(Number(abilitySid)),
    },
    actions: {
        async setSettings(settings: Partial<UserSettings>) {
            updating.value = true
            this.$state.settings = await useFetchCall('/userSettingsUpdate', settings) as UserSettings
            updating.value = false
        },
    },
})
