import type { Ref } from 'vue'
import { navigateTo } from '#app'
import * as Sentry from '@sentry/core'
import { ref, watch } from 'vue'

type Awaitable<T> = T | Promise<T>

// todo idea: брать из заголовков requestId для отображения в окне ошибки или для передачи в Sentry

const { fetch } = globalThis
const baseUrl = new URL('/api/ui', window.location.origin)

export interface FetchOpts {
    // Сформировать путь запроса
    makeUrl?: () => string
    contentType?: string
    // Сформировать body
    makeBody?: () => Record<string, any> | FormData | string
    onResponse?: (json: any) => Awaitable<void>
    onAbort?: () => void
    onError?: (err: Error) => Awaitable<void>
    waiting?: Ref<boolean>
    refAborted?: Ref<boolean>
    refError?: Ref<string>
    // фоновый запрос без блокировки UI
    background?: boolean
}

// !i! не использовать глобально (entityTotal не должен показывать прогресс)
export const fetchTime = ref(0)
export const fetchAbort = ref(0)

let timer: Timer
let fetchStarted = 0

class ServerError extends Error {
    constructor(message = '') {
        super(message)
        Object.defineProperties(this, {
            name: {
                configurable: true,
                enumerable: true,
                value: this.constructor.name,
                writable: true,
            },
            message: {
                configurable: true,
                enumerable: true,
                value: message,
                writable: true,
            },
        })
    }
}

export function fetcher<R>(
    {
        // call pending
        waiting = ref(true),
        refAborted = ref(false),
        refError = ref(''),
        makeUrl = () => '',
        makeBody = () => ({}),
        contentType = '',
        onAbort = () => {},
        onError = () => {},
        onResponse = (r: R) => {},
        background = false,
    }: FetchOpts,
) {
    let ac: AbortController

    const abort = () => {
        if (ac && waiting.value) {
            ac.abort()
        }
        ac = new AbortController()
        ac.signal.onabort = () => {
            refAborted.value = true
            onAbort()
        }
    }

    const RETRY_LIMIT = 3
    let retryLeft = RETRY_LIMIT

    const exec = () => {
        abort()

        waiting.value = true
        const endpoint = makeUrl()
        const url = baseUrl + endpoint
        const body = makeBody()
        const headers: Record<string, string> = {
            'user-agent': 'plm-ui/v0.0.0',
            ...Sentry.getTraceData(),
        }
        const init: RequestInit = {
            method: 'POST',
            headers,
            credentials: 'same-origin',
            signal: ac.signal,
        }
        if (contentType) {
            // direct
            // @ts-expect-error inject
            init.headers['content-type'] = contentType
            init.body = body as any
        } else if (body instanceof FormData) {
            // FormData
            init.body = body
        } else {
            // JSON
            // @ts-expect-error inject
            init.headers['content-type'] = 'application/json'
            init.body = JSON.stringify(body)
        }

        fetch(url, init)
            .then(async res => {
                if (res.status === 503) {
                    // [i] Nuxt частенько выбрасыват 503 ошибку при проксировании запроса к API
                    if (retryLeft) {
                        await new Promise(resolve => setTimeout(resolve, 400))
                        retryLeft--
                        console.warn('Retry503', { retryLeft })
                        return exec()
                    }
                }

                let json
                if (res.headers.get('Content-Type')?.split(';')[0] === 'application/json') {
                    json = await res.json()
                }
                if (!res.ok) {
                    if (!json) {
                        throw Object.assign(new ServerError(), {
                            // ответ от сервера получен
                            message: `Network error: ${res.statusText} (${res.status})`,
                            endpoint,
                        })
                    }

                    if (res.status === 401) {
                        sessionStorage.setItem('returnToAfterAuth', new URL(location.href).pathname)
                        await navigateTo('/auth', { external: true })
                    }
                    throw Object.assign(new ServerError(), {
                        statusCode: res.status,
                        statusText: res.statusText,
                        endpoint,
                        ...json,
                    })
                }
                retryLeft = RETRY_LIMIT
                await onResponse(json)
            })
            .catch(async err => {
                switch (err.name) {
                    case 'AbortError': {
                        // ignore
                        return
                    }
                    case 'TypeError': {
                        // Сетевая ошибка, запрос не дошёл до сервера (ответа нет)
                        err.message = `Network error: ${err.message}`
                        break
                    }
                }
                refError.value = err.message
                await onError(err)
            })
            .finally(() => {
                waiting.value = false
            })
    }

    if (!background) {
        watch(waiting, (to, from) => {
            if (from) {
                // stop
                clearInterval(timer)
                fetchTime.value = 0
            }
            if (to && !fetchTime.value) {
                // start
                fetchStarted = Date.now()
                fetchTime.value = 1 // для показа прогресса
                timer = setInterval(() => {
                    fetchTime.value = Date.now() - fetchStarted
                }, 500)
            }
        }, {
            immediate: true,
        })
        watch(fetchAbort, () => {
            abort()
        })
    }

    return {
        exec,
        abort,
        waiting,
        // @deprecated use waiting
        pending: waiting,
        aborted: refAborted,
        error: refError,
    }
}
